.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.slick-dots .slick-active {
	width: 30px;
	transition: 0.5 ease-in-out;
}

.slick-dots .slick-active button {
	background: yellow !important;
	width: 100% !important;
	transition: 0.5 ease-in-out;
}

.slick-dots li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 18px !important;
	height: 2px !important;
	padding: 0 !important;
	cursor: pointer;
	color: transparent;
	border: 0;
	outline: none;
	background: transparent;
	transition: 0.5 ease-in-out;
}

.slick-dots li button:before {
	font-family: 'slick';
	font-size: 6px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 2px !important;
	content: '' !important;
	text-align: center;
	opacity: 0.25;
	color: black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.chakra-step__indicator[data-status='complete'] {
	background: transparent !important;
	border: '1px solid green' !important;
	color: green !important;
}

.chakra-step__indicator[data-status='active'] {
	border-width: 2px;
	border-color: transparent !important;
}

.chakra-step__separator[data-orientation='vertical'] {
	width: 0 !important;
	background: transparent !important;
}

.chakra-step__indicator,
.chakra-step__indicator::before,
::after {
	border-color: transparent !important;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
